import React, { useState } from 'react';
import { Box, } from '@mui/material';
import TransactionsCard from '../cards/TransactionsCard';
import { formatDateToGivenFormat } from '../../../utils/utils';
import { myDebugPrint } from '../../../utils/debug';
import Slicer from '../../common/Slicer';

const ReportCashTransactions = ({ cashProject, updateCashProjectPayment,
    filteredDateStart, filteredDateEnd, filteringInProgress, onFilterDates, introSection = null, }) => {
    const [selectedCurrency, setSelectedCurrency] = useState(null);

    const select_another_currency = (newCurrencyFullName) => {
        setSelectedCurrency(
            newCurrencyFullName
        )
        myDebugPrint('new currency is selected: ' + newCurrencyFullName)
    }

    const get_currency_items = () => {
        const currencies_items = []
        if (!cashProject.currency_codes) {
            currencies_items = [
                'RUB, Российский рубль, 810'
            ]
        } else {
            for (const [code, name] of Object.entries(cashProject.currency_codes)) {
                if (code == '810') {
                    currencies_items.unshift(name + (name ? ', ' : '') + code)
                } else {
                    currencies_items.push(name + (name ? ', ' : '') + code)
                }
            }
        }
        if (selectedCurrency == null) {
            setSelectedCurrency(currencies_items[0])
        }
        return currencies_items
    }

    const currency_items = get_currency_items()

    return (
        <Box>
            {
                cashProject.currency_codes && Object.entries(cashProject.currency_codes).length > 1 &&
                <Slicer
                    title='Валюта'
                    items={currency_items}
                    selectedItems={selectedCurrency ?? currency_items[0]}
                    onToggle={select_another_currency}
                    singleSelect={true}
                    onSelectAll={() => { }}
                    onDeselectAll={() => { }}
                    columnsOnMD={4}
                    columnsOnXs={2}
                />
            }
            {
                selectedCurrency &&
                <TransactionsCard
                    projectId={cashProject.projectId}
                    currencyCode={selectedCurrency.slice(-3)}
                    updateCashProjectPayment={updateCashProjectPayment}
                    minDate={formatDateToGivenFormat({ date: cashProject.minDate, format: 'yyyy-MM-dd' })}
                    maxDate={formatDateToGivenFormat({ date: cashProject.maxDate, format: 'yyyy-MM-dd' })}
                    filteredDateStart={filteredDateStart}
                    filteredDateEnd={filteredDateEnd}
                    filteringInProgress={filteringInProgress}
                    onFilterDates={onFilterDates}
                    counterparties_list={cashProject.counterparties_list}
                    accessLevel={cashProject.accessLevel}
                    currencyName={selectedCurrency.slice(0, 3) == 'RUB' ? 'руб.' : selectedCurrency.slice(0, 3)}
                    introPage='CashProject'
                    introSection={introSection}
                />
            }
        </Box>
    )

}

export default ReportCashTransactions