import { Typography } from "@mui/material";
import IntroHelpButton from "../../../components/common/IntroHelpButton";

export const onboarding_ReportCashData_steps = [
    {
        element: ".onboarding-ReportCashData-center-screen",
        title: 'Добро пожаловать в ФинРадар!',
        intro: <Typography variant='body2'>
            Посмотрите всего 6 карточек, которые облегчат вашу работу в ФинРадаре.
        </Typography>,
    },
    {
        element: ".onboarding-ReportCashData-project",
        title: 'Анализ выписок по проектам',
        intro: <Typography variant='body2'>
            ФинРадар позволяет собрать и работать с выписками по разным счетам из разных банков в одном окне - проекте.
            <br />
            <br />
            Обычно 1 проект - это 1 должник со всеми его счетами.
            <br />
            <br />
            ФинРадар также позволяет загрузить в один проект целую группу взаимосвязанных компаний и анализировать их совместно.
        </Typography>,
    },
    {
        element: ".onboarding-ReportCashData-upload",
        title: 'Добавляйте данные в любое время',
        intro: <Typography variant='body2'>
            Выписки часто поступают не одновременно. Загружайте их в ФинРадар по мере поступления, потому что часто
            важно раньше узнать о подозрительных сделках, контрагентах-однодневках или потенциальной дебиторке.
            <br />
            <br />
            Используйте карточку добавления выписок, чтобы добавить к анализу этой организации новые выписки.
        </Typography>,
    },
    {
        element: ".onboarding-ReportCashData-types",
        title: 'Мгновенный отбор сомнительных платежей',
        intro: <Typography variant='body2'>
            ФинРадар автоматически проанализирует каждую из 100% транзакций и определит ее тип.
            <br />
            <br />
            Есть "безобидные" типы, такие как бюджетные платежи. Есть однозначно требующие внимания,
            такие как цессии, расчеты за третьих лиц, займы.
            <br />
            <br />
            Используйте дашборд по типам транзакций, чтобы быстро найти и посмотреть любые типы операций.
        </Typography>,
    },
    {
        element: ".onboarding-ReportCashData-counterparties",
        title: 'Расчеты по контрагентам',
        intro: <Typography variant='body2'>
            ФинРадар автоматически агрегирует все расчеты по каждому контрагенту.
            <br />
            <br />
            Используйте дашборд по контрагентам, чтобы провалиться в расчеты с любым контрагентом и посмотреть,
            когда и за что именно были расчеты.
        </Typography>,
    },
    {
        element: ".onboarding-ReportCashData-docs",
        title: 'Больше деталей в каждом дашборде',
        intro: <Typography variant='body2'>
            В правом верхнем углу каждой карточки ищите кнопку-подсказку.
            <br />
            <IntroHelpButton
                onClick={() => { }}
            />
            <br />
            Используйте ее, чтобы узнать больше информации про текущий дашборд.
        </Typography>,
    },
    {
        element: ".onboarding-ReportCashData-sections",
        title: 'И даже еще больше дашбордов',
        intro: <Typography variant='body2'>
            Еще больше информации про контрагентов - признаки однодневок, экономическая зависимость - в отдельном дашборде.
            <br />
            <br />
            Мощные фильтры и отборы по контрагентам и назначениям платежей вместе с периодами расчетов - в реестре платежей.
        </Typography>,
    },
    {
        element: ".onboarding-ReportCashData-center-screen",
        title: 'Мы на связи!',
        intro: <Typography variant='body2'>
            Мы рады вашим предложениям и готовы ответить на вопросы. Пишите на web@brpadvice.ru или напрямую вашему менеджеру в мессенджер.
            <br />
            <br />
            <span style={{ color: '#c20000' }}>❤</span> ФинРадар
        </Typography>,
    },
]