import axios from 'axios';
import { getToken, storeToken, storeUsername, storeEmail, storeName, cleanAuth } from '../storage.js';
import { unauthorized, slowNetwork, unhandledNetworkIssue, unpaid } from '../vars';

// Create an Axios instance
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 90000, // 90 seconds timeout
});

// **Request Interceptor** - Automatically adds Authorization token if available
api.interceptors.request.use(
    (config) => {
        const token = getToken();
        const authHeaders = [];
        authHeaders.push(`Basic ${process.env.REACT_APP_API_KEY}`)
        if (token) {
            authHeaders.push(`Bearer ${token}`);
        }
        config.headers.Authorization = authHeaders.join(', ')
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// **Response Interceptor** - Handles HTTP errors globally
api.interceptors.response.use(
    (response) => response, // Pass successful responses through
    async (error) => {
        if (!error.response) {
            // Network error
            return Promise.reject({ code: 510, message: slowNetwork });
        }

        const status = error.response.status;

        if (status === 401) {
            // Unauthorized - Token expired or invalid
            cleanAuth();
            window.location.href = '/signin';
            return Promise.reject({ code: 401, message: unauthorized });
        } else if (status === 402) {
            // Access forbidden, unpaid
            window.location.href = '/myplan';
            return Promise.reject({ code: 402, message: unpaid });
        }

        // if (status === 500) {
        //     // Server error
        //     return Promise.reject({ code: 500, message: 'Internal Server Error' });
        // }

        // if (status === 403) {
        //     // Forbidden
        //     return Promise.reject({ code: 403, message: 'You do not have permission to perform this action.' });
        // }

        // if (status === 404) {
        //     // Not found
        //     return Promise.reject({ code: 404, message: 'Resource not found' });
        // }

        // Default case - pass the error response message
        return Promise.reject({
            code: status,
            message: error.response.data?.error || unhandledNetworkIssue,
        });
    }
);

export default api;
