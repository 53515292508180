import { DoneAll } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import SlicerButtonDemo from "./SlicerButtonDemo";
import { myDebugPrint } from "../../utils/debug";

export const intro_OverallsByTransactionTypes_steps = [
    {
        element: ".intro-OverallsByTransactionTypes-title",
        title: 'Анализ содержания транзакции',
        intro: <Typography variant='body2'>
            ФинРадар автоматически сканирует каждый платеж, чтобы определить его тип.
            <br />
            Цессии, займы, кредиты, налоговые платежи, лизинг, выдача под отчет и многое другое распределяется по группам платежей автоматически.
        </Typography>,
    },
    {
        element: ".intro-slicer-transaction-first-item",
        title: 'Фильтры по типам транзакций',
        intro:
            <Typography variant='body2'>
                По умолчанию в этой карточке включены все типы платежей.
                <br />
                Используйте фильтр по типам транзакций, чтобы отобрать или скрыть определенные категории.
                <br />
                Чтобы добавить или убрать нужный тип платежей, нажмите на него.
                <SlicerButtonDemo isOn={true} />
                <SlicerButtonDemo isOn={false} />
                <br />
            </Typography>
        ,
    },
    {
        element: ".intro-slicer-select-all",
        title: 'Сброс фильтра',
        intro: <Typography variant='body2'>
            Включить или выключить все сразу можно этой кнопкой в правом верхнем углу фильтра.
        </Typography>,
    },
    {
        element: ".intro-slicer-payments-list",
        title: 'Каждая транзакция под рукой',
        intro: <Typography variant='body2'>
            Когда настроены нужные фильтры, нажмите "Показать платежи", чтобы посмотреть все соответствующие платежи.
        </Typography>,
    },
]