import { Typography } from "@mui/material";

export const intro_MissingAccounts_steps = [
    {
        element: ".intro-MissingAccounts-title",
        title: 'ФинРадар покажет счета, по которым не загружены выписки',
        intro: <Typography variant='body2'>
            ФинРадар автоматически определяет счета, упомянутые в загруженных файлах и принадлежащие этому же владельцу.
            <br />
            Счета, по которым не загружены файлы-выписки, попадают в этот список.
        </Typography>,
    },
    {
        element: ".intro-MissingAccounts-title",
        title: 'Полный список счетов в справке из налоговой',
        intro: <Typography variant='body2'>
            ФинРадар находит все счета, упоминающиеся в загруженных выписках.
            <br />
            Полная информация об открытых и закрытых счетах должна быть в справках из налоговой.
        </Typography>,
    },
]