import { Typography } from "@mui/material";

export const intro_TransactionsCard_steps = [
    {
        element: ".intro-TransactionsCard-title",
        title: 'Гибкий отбор и поиск платежей',
        intro: <Typography variant='body2'>
            Все платежи и поступления по всем выпискам сведены в единый реестр платежей.
            <br />
            <br />
            Используйте поиск и отбор по контрагенту, назначению платежа и периоду расчетов, чтобы посмотреть расчеты по сделкам, найти все операции по кредитору, проверить погашение дебиторки.
        </Typography>,
    },
    {
        element: ".intro-TransactionsCard-title",
        title: 'Отбор расчетов с КДЛ и связанными лицами',
        intro: <Typography variant='body2'>
            Используйте фильтр по контрагентам, чтобы отобрать любых интересующих лиц: руководители, бенефициары и их родственники, аффилированные организации.
        </Typography>,
    },
    {
        element: ".intro-TransactionsCard-title",
        title: 'Работайте в ФинРадаре',
        intro: <Typography variant='body2'>
            и выгружайте данные в Excel, чтобы подготовить претензию и включить полные реквизиты любого и каждого платежного документа.
        </Typography>,
    },
]