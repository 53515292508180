import React, { useState, } from 'react';
import { Container, Grid, Typography, Box, Card, CardContent, Button, ToggleButton, ToggleButtonGroup, } from '@mui/material';
import Spacer from './Spacer';
import { Link as ScrollLink } from 'react-scroll';

const yearly = 'yearly'
const quarterly = 'quarterly'
const monthly = 'monthly'

const TariffSwitcher = ({ initialTerm = yearly, onChange }) => {
    const [selectedTerm, setSelectedTerm] = useState(initialTerm);

    const handleTermChange = (event, newTerm) => {
        if (newTerm) {
            setSelectedTerm(newTerm);
            onChange && onChange(newTerm); // Trigger the callback when the term changes
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'center',
                gap: 0,
            }}
        >
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                При единовременной оплате
            </Typography>
            <ToggleButtonGroup
                color="primary"
                value={selectedTerm}
                exclusive
                onChange={handleTermChange}
                sx={{
                    display: 'flex',
                    gap: 0,
                    '& .MuiToggleButton-root': {
                        borderRadius: 0,
                        px: 4,
                        py: 1.5,
                        fontWeight: 500,
                    },
                }}
            >
                <ToggleButton value={monthly}>за месяц</ToggleButton>
                <ToggleButton value={quarterly}>за квартал</ToggleButton>
                <ToggleButton value={yearly}>за год</ToggleButton>
            </ToggleButtonGroup>
        </Box>
    );
};

function PricingCard({ title, subheading, price, period, featuresCashAnalysis, featuresCompaniesCheck, featuresOther, buttonText, buttonHref }) {
    return (
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box>
                    <Typography variant="h2" component="div">
                        {title}
                    </Typography>
                    <Typography variant="h3" component="div">
                        {price}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                        {period}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                        {subheading}
                    </Typography>
                    {
                        featuresCashAnalysis && featuresCashAnalysis.length > 0 &&
                        <Box>
                            <Typography variant="body2">
                                <strong>Анализ выписок</strong>
                            </Typography>
                            {featuresCashAnalysis.map((feature, index) => (
                                <Typography variant="body2" color="text.secondary" key={'cash_' + index}>
                                    ⦁ {feature}
                                </Typography>
                            ))}
                        </Box>
                    }
                    {
                        featuresCompaniesCheck && featuresCompaniesCheck.length > 0 &&
                        <Box>
                            <Typography variant="body2">
                                <strong>Проверка компаний и ИП</strong>
                            </Typography>
                            {featuresCompaniesCheck.map((feature, index) => (
                                <Typography variant="body2" color="text.secondary" key={'checks_' + index}>
                                    ⦁ {feature}
                                </Typography>
                            ))}
                        </Box>
                    }
                    {
                        featuresOther && featuresOther.length > 0 &&
                        <Box>
                            {featuresOther.map((feature, index) => (
                                <Typography variant="body2" color="text.secondary" key={'checks_' + index}>
                                    ⦁ {feature}
                                </Typography>
                            ))}
                        </Box>
                    }
                </Box>
                <ScrollLink to="orderForm" smooth={true} duration={500}>
                    <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                        {buttonText}
                    </Button>
                </ScrollLink>
            </CardContent>
        </Card>
    );
}

function PricingSection() {
    const [term, setTerm] = useState(yearly);

    const pricingPlans = [
        {
            title: 'Легкий',
            subheading: <span>Идеально для анализа небольших и средних компаний / ИП</span>,
            priceYearly: '1 470 руб.',
            priceQuarterly: '1 890 руб.',
            priceMonthly: '2 100 руб.',
            period: 'в месяц',
            featuresCashAnalysis: [
                'Количество объектов анализа (ЮЛ / ИП / ФЛ / группа ЮЛ и ИП): безлимитно',
                'Количество расчетных счетов: безлимитно',
                'Количество новых транзакций, загружаемых в ФинРадар: 5 000 / месяц',
                'Количество транзакций, хранящихся в ФинРадаре: 25 000',
                'Риски: анализ расчетов по статусам контрагентов – недостоверные данные в ЕГРЮЛ, массовый адрес, массовый руководитель / учредитель; высокая доля встречных оборотов; высокая доля оборотов к выручке контрагента: 100 / объект анализа',
            ],
            featuresCompaniesCheck: [
                'Актуальные данные ЕГРЮЛ и ЕГРИП (ЮЛ / ИП): 1 000 / месяц',
                'Исторические данные ЕГРЮЛ и ЕГРИП (ЮЛ / ИП): 100 / месяц',
            ],
            featuresOther: [
            ],
            buttonText: 'Оформить >>>',
            buttonHref: '#form',
        },
        {
            title: 'Бизнес',
            subheading: <span>Идеально для профессионалов с большим объемом работы</span>,
            priceYearly: '3 640 руб.',
            priceQuarterly: '4 680 руб.',
            priceMonthly: '5 200 руб.',
            period: 'в месяц',
            featuresCashAnalysis: [
                'Количество объектов анализа (ЮЛ / ИП / ФЛ / группа ЮЛ и ИП): безлимитно',
                'Количество расчетных счетов: безлимитно',
                'Количество новых транзакций, загружаемых в ФинРадар: 20 000 / месяц',
                'Количество транзакций, хранящихся в ФинРадаре: 100 000',
                'Риски: анализ расчетов по статусам контрагентов – недостоверные данные в ЕГРЮЛ, массовый адрес, массовый руководитель / учредитель; высокая доля встречных оборотов; высокая доля оборотов к выручке контрагента: 100 / объект анализа',
            ],
            featuresCompaniesCheck: [
                'Актуальные данные ЕГРЮЛ и ЕГРИП (ЮЛ / ИП): 1 000 / месяц',
                'Исторические данные ЕГРЮЛ и ЕГРИП (ЮЛ / ИП): 100 / месяц',
            ],
            featuresOther: [
            ],
            buttonText: 'Оформить >>>',
            buttonHref: '#form',
        },
        {
            title: 'Корп',
            subheading: <span>Индивидуальные условия<br />&nbsp;</span>,
            priceYearly: 'от 2 800 руб.',
            priceQuarterly: 'от 3 600 руб.',
            priceMonthly: 'от 4 000 руб.',
            period: 'в месяц / пользователь',
            featuresCashAnalysis: [
            ],
            featuresCompaniesCheck: [
            ],
            featuresOther: [
                'Личный менеджер',
                'Интеграция индивидуальных маркеров',
                'Настройка индивидуальных отчетов',
                'Совместный доступ к проектам анализа выписок',
                'От 10 пользователей',
            ],
            buttonText: 'Оформить >>>',
            buttonHref: '#form',
        },
    ];

    const additionalOptions = [
        'работа с машинно-сформированными файлами txt для 1C, Excel – бесплатно',
        'работа с файлами Word и PDF – зависит от способа формирования файла: бесплатно или от 10 рублей / страница. Оценивается при загрузке файла в сервис',
        'дополнительные 10 000 новых транзакций и 50 000 транзакций, хранящихся в ФинРадаре: 3 900 руб.',
        'дополнительные 25 000 новых транзакций и 125 000 транзакций, хранящихся в ФинРадаре: 8 400 руб.',
        'дополнительные 50 000 новых транзакций и 250 000 транзакций, хранящихся в ФинРадаре: 13 000 руб.',
        'дополнительные 100 000 новых транзакций и 500 000 транзакций, хранящихся в ФинРадаре: 22 700 руб.',
    ];

    return (
        <Box sx={{ backgroundColor: 'white', py: 4 }}>
            <Container maxWidth="lg">
                <Spacer height="20px" />
                <Typography variant="h2" gutterBottom
                    sx={{
                        fontSize: '2.1em',
                        color: '#5E5C5C',
                        fontWeight: 'bold',
                    }}>
                    Тарифы на ФинРадар
                </Typography>
                <Spacer height="20px" />
                <TariffSwitcher initialTerm={term} onChange={setTerm} />
                <Spacer height="20px" />
                <Grid container spacing={4}>
                    {pricingPlans.map((plan, index) => {
                        plan.price = term == yearly
                            ? plan.priceYearly :
                            (term == quarterly ? plan.priceQuarterly : plan.priceMonthly);
                        return (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <PricingCard {...plan} />
                            </Grid>
                        );
                    })}
                </Grid>
                <Spacer height="40px" />
                <Typography variant="h3" gutterBottom>
                    Дополнительные опции к любому тарифу анализа выписок
                </Typography>
                <ul>
                    {additionalOptions.map((option, index) => (
                        <li key={index}>
                            <Typography variant="body2" color="text.secondary">
                                {option}
                            </Typography>
                        </li>
                    ))}
                </ul>
                <Spacer height="40px" />
            </Container>
        </Box>
    );
}

export default PricingSection;
