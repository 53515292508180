import { Typography } from "@mui/material";

export const intro_fileProcessingResults_steps = [
    {
        element: ".intro-FileProcessingResults-title",
        title: 'Контрольная карточка',
        intro: <Typography variant='body2'>
            Здесь вы найдете результаты загрузки каждого файла.
            <br />
            ФинРадар автоматически проверяет контрольные суммы, обороты, остатки по каждой выписке.
            <br />
            Если данные не сходятся, вы увидите информацию о расхождении.
        </Typography>,
    },
    {
        element: ".intro-FileProcessingResults-icon",
        title: 'Статус обработки',
        intro: <Typography variant='body2'>
            Такая иконка есть в заголовке карточки и отдельно у каждой выписки.
            <br />
            <span style={{ color: 'green', fontSize: 'larger' }}>✓</span> - означает, что в выписке нет расхождений.
            <br />
            <span style={{ color: 'red', fontSize: 'larger' }}>⚠</span> - означает, что в выписке есть расхождения.
            <br />
            <span style={{ color: 'orange', fontSize: 'larger' }}>⁉</span> - означает, что файл еще обрабатывается.
        </Typography>,
    },
    {
        element: ".intro-FileProcessingResults-icon",
        title: 'Если есть расхождения',
        intro: <Typography variant='body2'>
            Если в выписке выявлены расхождения, уточнить детали можно у команды ФинРадара.
            <br />
            Мы отправим комментарий в мессенджере и покажем в таблице отдельным столбцом.
        </Typography>,
    },
]