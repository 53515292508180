export const wrongcredentials = 'Неверный логин или пароль';
export const wrongtoken = 'Неверный токен или email';
export const unauthorized = 'Требуется войти в аккаунт';
export const unauthorizedDb = 'Ошибка авторизациии. Повторите попытку через мииуту. Если ошибка повторится, выйдите и войдите в аккаунт.';
export const unauthorizedCTA = 'Нажмите здесь, чтобы войти в аккаунт.';

export const unauthorizedDbCTA = 'Нажмите здесь, чтобы заново войти в аккаунт.';

export const accountExists =
    'Такой аккаунт уже существует. Войдите или создайте новый аккаунт';

export const unauthorizedExpired = 'Ваша сессия истекла. Войдиите заново';

export const unauthorizedExpiredCTA =
    'Нажмите здесь, чтобы заново войти в аккаунт.';

export const slowNetwork =
    'Не удается подключиться к серверу. Попробуйте еще раз через несколько минут.';
export const noNetwork = 'Не удается подключитиься к серверу. Проверьте, что устройство подключено к сети Интернет';
export const unhandledNetworkIssue =
    'Ого! Прозошла какая-то необычная ошибка. Мы уже исправляем ее. Попробуйте еще раз через несколько минут. При необходмости напишите в поддержку';
export const error500 = 'Ошибка на сервере. Мы уже исправляем ее. Попробуйте еще раз через несколько минут. При необходмости напишите в поддержку';
export const emptyResponse =
    'Сервер отправил ответ, но он пуст. Попробуйте еще раз через несколько минут. При необходмости напишите в поддержку';
export const tooShortQuery = "Слишком короткий запрос";
export const unpaid = "Оплаченный период закончился";

export const Divisions = {
    ONE: 'one',
    THS: 'ths',
    MLN: 'mln',
};

export const DivisionProperty = {
    NUMBER: 'number',
    SHORT_NAME: 'shortName',
};

export const getDivisionProperty = (division, divisionProperty) => {
    switch (divisionProperty) {
        case DivisionProperty.NUMBER:
            switch (division) {
                case Divisions.ONE:
                    return 1;
                case Divisions.THS:
                    return 1000;
                case Divisions.MLN:
                    return 1000000;
                default:
                    return 1000;
            }
        case DivisionProperty.SHORT_NAME:
            switch (division) {
                case Divisions.ONE:
                    return 'руб.';
                case Divisions.THS:
                    return 'тыс. руб.';
                case Divisions.MLN:
                    return 'млн. руб.';
                default:
                    return 'тыс. руб.';
            }
        default:
            return null;
    }
};

export const inflowsColor = '#82ca9d';
export const outflowsColor = '#d81462';
export const netflowsColor = '#fda836';
export const balancesColor = '#8303ff';

export const monthNames = [
    'Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн',
    'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'
];

export const transactionTypes = [
    'Бюджетный платеж (уплата налогов, сборов, взносов, пошлин)',
    'Дивиденды',
    'Займы',
    'Заработная плата и аналогичные платежи',
    'Лизинг',
    'Операции по корпоративной карте',
    'Подотчетные и снятие наличных',
    'Расчеты по депозитам',
    'Расчеты по кредитам',
    'Расчеты с бюджетными организациями',
    'Расчеты с ИП',
    'Расчеты с некоммерческими организациями',
    'Расчеты с физическими лицами',
    'Расчеты с финансовыми организациями',
    'Расчеты с юридическими лицами',
    'РКО и связанные расходы и доходы',
    'Собственные счета',
    'Цессии и оплаты за третье лицо',
    'Эквайринг',
]

export const standard_error_codes = {
    unpaid: 402,
}