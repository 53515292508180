import { Typography } from "@mui/material";

export const intro_RisksMainData_steps = [
    {
        element: ".intro-RisksMainData-title",
        title: 'Автоматическая проверка контрагентов',
        intro: <Typography variant='body2'>
            ФинРадар для каждого проекта проверяет 100 ключевых контрагентов на риски по ЕГРЮЛ, а также на маркеры экономической взаимозависимости.
        </Typography>,
    },
    {
        element: ".intro-RisksMainData-title",
        title: 'ЕГРЮЛ-маркеры',
        intro: <Typography variant='body2'>
            ФинРадар проверяет, есть ли особые отметки в реестре - массовый адрес или массовые руководитель / учредители, недостоверные сведения.
            <br />
            <br />
            Часто такая запись будет указывать на контрагента-однодневку, через которого выводили активы.
        </Typography>,
    },
    {
        element: ".intro-RisksMainData-title",
        title: 'ЕГРЮЛ-маркеры',
        intro: <Typography variant='body2'>
            При этом сам факт записи о массовости часто не означает, что контрагент - однодневка. Например, адрес многих системообразующих банков -
            это адрес массовой регистрации; а успешные предприниматели часто имеют долю во множестве юрлиц.
        </Typography>,
    },
    {
        element: ".intro-RisksMainData-title",
        title: 'Экономические маркеры',
        intro: <Typography variant='body2'>
            ФинРадар рассчитает долю встречных оборотов. Если между контрагентами расчеты идут в обе стороны, и доля встречных оборотов велика,
            то на операции часто стоит посмотреть внимательнее.
            <br />
            <br />
            Например, если в одну сторону идут платежи за сырье, а в другую - за готовую продукцию, то это похоже на вывод прибыли.
        </Typography>,
    },
    {
        element: ".intro-RisksMainData-title",
        title: 'Экономические маркеры',
        intro: <Typography variant='body2'>
            ФинРадар поможет также сравнить выручку контрагента с суммой платежей или поступлений.
            <br />
            <br />
            Почему это важно - если значительная доля выручки контрагента приходится на расчеты с анализируемой компанией,
            то это похоже на экономическую зависимость, а сделки могут носить нерыночный характер.
        </Typography>,
    },
]