import React from 'react';
import { Box, ThemeProvider } from '@mui/material';
import { slicerTheme } from '../../theme';
import { myDebugPrint } from '../../utils/debug';

const SlicerDisplayDemo = ({ isOn = true, onClick = () => { } }) => {
    return (
        <Box
            style={{
                backgroundColor: isOn ? '#d0e2cd' : '#fff',
                borderRadius: '5px',
                borderWidth: '2px',
                border: '#d0e2cd',
                borderStyle: 'solid',
                justifyContent: 'left',
                textWrap: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
                overflow: 'hidden',
                textAlign: 'left',
                paddingLeft: '8px',
                paddingRight: '8px',
                whiteSpace: 'nowrap',
                marginTop: '3px'
            }}
        >
            {isOn ? 'ВКЛЮЧЕН' : 'ВЫКЛЮЧЕН'}
        </Box>
    );
};

export default SlicerDisplayDemo;
