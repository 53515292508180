// components/OrderFormSection.js
import React, { useState } from 'react';
import { Container, Grid, Typography, TextField, Checkbox, Button, Box, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import ApiManager from '../../utils/api/api';
import CircleLoader from 'react-spinners/CircleLoader';
import { useSnackbar } from '../../context/SnackbarContext';
import { myDebugPrint } from '../../utils/debug';

const Spacer = styled('div')(({ height }) => ({
    height: height || '20px',
}));

function OrderFormSection() {
    const showSnackbar = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const [sentMessage, setSentMessage] = useState('');
    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        files: [],
        acceptance: false,
    });

    const toggleSent = () => {
        setSent(!sent)
        setSentMessage('')
    }

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formValues.acceptance) {
            showSnackbar('Согласие с условиями обязательно!', 'error')
            return;
        }
        if (loading) return;
        setLoading(true);
        try {
            const response = await ApiManager.sendRequest({
                name: formValues.name,
                email: formValues.email,
                phone: formValues.phone,
            });
            myDebugPrint(`response: ${JSON.stringify(response)}`)
            if (!response || !response.result) {
                showSnackbar('Невозможно отправить заявку. Если вы не робот, напишите на info@brpadvice.ru', 'warning');
                setSentMessage('Невозможно отправить заявку. Если вы не робот, напишите на info@brpadvice.ru')
                setSent(true);
            } else if (response && response.signIn) {
                showSnackbar(`Заявка отправлена. Проверьте почту`, 'success')
                setSentMessage('Заявка отправлена. В течение нескольких минут на ваш email придет письмо с данными для входа. При необходимости свяжитесь с нами по info@brpadvice.ru')
                setSent(true);
            } else {
                showSnackbar(`Заявка отправлена. Мы скоро свяжемся с вами`, 'success')
                setSentMessage('Заявка отправлена. Наша команда свяжется с вами в ближайшее время. При необходимости свяжитесь с нами по info@brpadvice.ru')
                setSent(true);
            }
        } catch (error) {
            showSnackbar('Невозможно отправить заявку. Если вы не робот, напишите на info@brpadvice.ru', 'warning');
            setSentMessage('Невозможно отправить заявку. Если вы не робот, напишите на info@brpadvice.ru')
            setSent(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ backgroundColor: 'white', py: 4 }} id="form">
            <Container maxWidth="md">
                <Spacer height="20px" />
                <Typography variant="h2" gutterBottom
                    sx={{
                        fontSize: '2.1em',
                        color: '#5E5C5C',
                        fontWeight: 'bold',
                    }}>
                    Оформить заявку на ФинРадар
                </Typography>
                <Typography variant="body1">
                    Напишите на <a href="mailto:info@brpadvice.ru">info@brpadvice.ru</a> или заполните форму:
                </Typography>
                <Box>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3} sx={{ marginTop: 0 }}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Как к вам обращаться"
                                    name="name"
                                    value={formValues.name}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Email"
                                    name="email"
                                    type="email"
                                    value={formValues.email}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Телефон"
                                    name="phone"
                                    type="tel"
                                    value={formValues.phone}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="acceptance"
                                            checked={formValues.acceptance}
                                            onChange={handleChange}
                                            required
                                        />
                                    }
                                    label="Отправка формы означает согласие на обработку предоставленных данных"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {loading &&
                                    <CircleLoader color="#8A4B9A" className='spinner' size={45} />
                                }
                                {!loading && !sent &&
                                    <Button type="submit" variant="contained" color="primary" fullWidth>
                                        <i className="fa fa-paper-plane" aria-hidden="true" style={{ marginRight: '8px' }}></i>
                                        Отправить
                                    </Button>
                                }
                                {!loading && sent &&
                                    <Box>
                                        <Typography variant='body2'>
                                            {sentMessage}
                                        </Typography>
                                        <Typography variant='body2'
                                            onClick={toggleSent}
                                        >
                                            <span
                                                style={{
                                                    textDecoration: "underline",
                                                    cursor: "pointer",
                                                    color: "#1976D2",
                                                }}
                                            >
                                                Отправить заявку заново
                                            </span>
                                        </Typography>
                                    </Box>
                                }
                            </Grid>
                        </Grid>
                    </form>
                </Box>
                <Spacer height="40px" />
            </Container>
        </Box>
    );
}

export default OrderFormSection;
