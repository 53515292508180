import { Typography } from "@mui/material";

export const intro_overallByAccounts_steps = [
    {
        element: ".intro-OverallByAccounts-title",
        title: 'Контрольная карточка',
        intro: <Typography variant='body2'>
            Здесь вы найдете сводные данные по каждому счету.
            <br />
            ФинРадар покажет, соответствуют ли обороты остаткам. Если есть расхождение, какие-то выписки могли быть неполными или с ошибками.
            <br />
            Например, у некоторых банков иногда в выписке "теряются" платежи. Но чаще проблема проще - не все выписки загружены или что-то загружено дважды.
        </Typography>,
    },
    {
        element: ".intro-OverallByAccounts-title",
        title: 'Проверяйте период выгрузки',
        intro: <Typography variant='body2'>
            ФинРадар показывает для каждого счета график, на котором видно, за какие даты предоставлены выписки.
            <br />
            <br />
            Следите, чтобы между выписками не было разрывов (разрыв - это период, за который выписка не загружена):
            <br />
            <br />
            <img
                src="/assets/images/vypiski-razryv.png"
                alt="Приимер выписки с разрывами"
                className="logo-image-authscreen"
                style={{ width: '100%', maxHeight: '4em', height: 'auto' }}
            />
        </Typography>,
    },
    {
        element: ".intro-OverallByAccounts-title",
        title: 'Проверяйте период выгрузки',
        intro: <Typography variant='body2'>
            Также следите, чтобы выписки не накладывались друг на друга (наложение - это период, за который операции загружены несколько раз):
            <br />
            <br />
            <img
                src="/assets/images/vypiski-nalozhenie.png"
                alt="Приимер выписки с наложением"
                className="logo-image-authscreen"
                style={{ width: '100%', maxHeight: '4em', height: 'auto' }}
            />
            <br />
            <br />
            Если выписки накладываются друг на друга, свяжитесь с вашим менеджером для удаления дубликатов.
        </Typography>,
    },
]