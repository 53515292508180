import React, { useState } from 'react';
import ApiManager from '../../../utils/api/api';
import { Box, Card, CardHeader, } from '@mui/material';
import ToggleIconButton from '../../common/ToggleIconButton';
import UnfoldLess from '@mui/icons-material/UnfoldLess';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import IntroHelpButton from '../../common/IntroHelpButton';
import { Steps, Hints } from "intro.js-react";

import "intro.js/introjs.css";
import { myDebugPrint } from '../../../utils/debug';

const CashCardTemplate = ({ title, subheader, action, cardcontent, width = '100%', initalFolded = false,
    steps = null, hints = null, introFiltersCallback = null, introPage = null, introSection = null, introCard = null,
}) => {
    const [isFolded, setIsFolded] = useState(initalFolded);
    const [introStepsEnabled, setIntroStepsEnabled] = useState(false)
    const [initialStep, setInitialStep] = useState(0)
    const [hintsEnabled, setHintsEnabled] = useState(false)

    const toggleIntroStepsEnabled = (forceExit = false) => {
        if (forceExit != true && introFiltersCallback) {
            introFiltersCallback()
        }
        setIntroStepsEnabled(forceExit ? false : !introStepsEnabled)
    }

    const extractTextFromJSX = (jsx) => {
        if (!jsx) return "";

        const extractText = (element) => {
            if (typeof element === "string") {
                return element; // Keep plain text
            } else if (React.isValidElement(element)) {
                return extractText(element.props.children); // Recursively extract from children
            } else if (Array.isArray(element)) {
                return element.map(extractText).join(" "); // Flatten arrays of children
            }
            return "";
        };

        return extractText(jsx).trim();
    };

    const smoothScrollToElement = (element) => {
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };

    const handleStepChange = (nextStepIndex, docType) => {
        if (!steps || nextStepIndex < 0 || nextStepIndex >= steps.length) return;

        const step = steps[nextStepIndex]; // Get the next step
        const targetElement = document.querySelector(step.element);

        if (targetElement) {
            smoothScrollToElement(targetElement); // Smoothly scroll before Intro.js moves
            // setTimeout(() => self.steps().goToStep(nextStepIndex), 500); // Delay to ensure smooth transition
        }
        noteSteps(nextStepIndex, docType)
    };

    const noteSteps = (stepIndex, docType) => {
        if (!introStepsEnabled) { return }
        if (!steps || stepIndex < 0 || stepIndex >= steps.length) return;

        const step = steps[stepIndex]; // Get current step object
        myDebugPrint(`CashCardTemplate: step: ${JSON.stringify(step)}`)
        const plainText = extractTextFromJSX(step.intro);
        const stepData = {
            docType: docType, // Passed as a prop
            section: introSection,
            card: introCard,
            text: `${step.title}: ${plainText}`
        };

        // Fire and forget (no waiting for a response)
        ApiManager.steps(stepData)
            .catch(e => myDebugPrint(`Error on note step: ${e}`));
    };


    return (
        <Box>
            {!(!steps) &&
                <Steps
                    enabled={introStepsEnabled}
                    steps={steps}
                    initialStep={initialStep}
                    onChange={(nextStepIndex) => handleStepChange(nextStepIndex, 'detailedDocs')}
                    onExit={() => toggleIntroStepsEnabled(true)}
                    options={{
                        hidePrev: true,
                        prevLabel: "Назад",
                        nextLabel: "Далее",
                        doneLabel: "Ок",
                        hintAnimation: true,
                        disableInteraction: false,
                        scrollToElement: false, // Ensures scrolling happens
                        scrollTo: 'element'    // Only scrolls the element into view                        
                    }}
                />}
            {!(!hints) &&
                <Hints enabled={hintsEnabled} hints={hints} />
            }
            <Card style={{ width: width, marginBottom: '30px' }} >
                <CardHeader
                    onClick={() => {
                        if (isFolded) {
                            setIsFolded(!isFolded)
                        }
                    }
                    }
                    title={title}
                    subheader={subheader}
                    action={
                        <Box sx={{ display: 'flex' }}>
                            {
                                (steps) &&
                                <Box className="onboarding-ReportCashData-docs">
                                    <IntroHelpButton
                                        onClick={() => toggleIntroStepsEnabled()}
                                    />
                                </Box>
                            }
                            {!isFolded &&
                                action
                            }
                            <ToggleIconButton
                                icon={isFolded ? <UnfoldMore /> : <UnfoldLess />}
                                isOn={!isFolded}
                                onSelectAll={() => setIsFolded(!isFolded)}
                                onDeselectAll={() => setIsFolded(!isFolded)}
                                backgroundColorOn='#fdbc30'
                                borderColorOn='#fdbc30'
                                backgroundColorOff='#d0e2cd'
                                borderColorOff='#d0e2cd'
                            />
                        </Box>
                    }
                />
                {!isFolded &&
                    cardcontent
                }
            </Card>
        </Box>
    );
};

export default CashCardTemplate;