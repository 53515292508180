import { Typography } from "@mui/material";

export const intro_CounterpartiesCard_steps = [
    {
        element: ".intro-CounterpartiesCard-title",
        title: 'Контрагенты как на ладони',
        intro: <Typography variant='body2'>
            ФинРадар агрегирует все выписки, чтобы показать полную сумму расчетов с каждым контрагентом.
            <br />
            <br />
            Юрлица и ИП идентифицируем по ИНН. Физлиц - по ИНН, если указан в выписке, или по ФИО, когда ИНН нет.
        </Typography>,
    },
    {
        element: ".intro-CounterpartiesCard-tabs",
        title: 'Все проранжированы по значимости',
        intro: <Typography variant='body2'>
            Все контрагенты оценены по значимости в расчетах: по роли в поступлениях, по роли в платежах, а также по встречным оборотам (поступления минус платежи).
        </Typography>,
    },
    {
        element: ".intro-CounterpartiesCard-dates-filter",
        title: 'Фильтр по датам операций для оспаривания сделок',
        intro: <Typography variant='body2'>
            Используйте фильтр по датам, чтобы работать только с теми расчетами, которые попадают в нужный период.
        </Typography>,
    },
    {
        element: ".intro-CounterpartiesCard-subtitle",
        title: 'Расширенные возможности анализа',
        intro: <Typography variant='body2'>
            Еще больше возможностей для анализа в разделах "Риски" и "Реестр платежей".
        </Typography>,
    },
]