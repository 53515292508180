import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Box, Grid, Card, CardContent, CardHeader } from '@mui/material';
import { inflowsColor, netflowsColor, outflowsColor, balancesColor } from '../../../utils/vars';
import { formatMonth, shortenCashNumbers, getCurrencyName } from '../../../utils/utils';
import { myDebugPrint } from '../../../utils/debug';
import CashCardTemplate from './CashCardTemplate';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const OverallByMonths = ({ transactionData, closingBalances }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    // Parse the transaction data and categorize by currency
    const parseTransactionDataByCurrency = (data) => {
        const currencyData = {};

        data.forEach((item) => {
            const currencyCode = item.CurrencyCode;
            const month = `${item.TransactionYear}-${item.TransactionMonth.toString().padStart(2, '0')}`;

            if (!currencyData[currencyCode]) {
                currencyData[currencyCode] = {
                    inflows: {},
                    outflows: {},
                    netChangeInCash: {},
                    earliestDate: null,
                    latestDate: null,
                };
            }

            const currencyInfo = currencyData[currencyCode];
            currencyInfo.inflows[month] = item.Inflows ?? 0;
            currencyInfo.outflows[month] = item.Outflows ?? 0;
            currencyInfo.netChangeInCash[month] = item.Netflows ?? 0;

            const date = new Date(item.TransactionYear, item.TransactionMonth - 1);
            if (!currencyInfo.earliestDate || date < currencyInfo.earliestDate) currencyInfo.earliestDate = date;
            if (!currencyInfo.latestDate || date > currencyInfo.latestDate) currencyInfo.latestDate = date;
        });

        return currencyData;
    };

    const currencyData = parseTransactionDataByCurrency(transactionData);

    // Parse the closing balances and categorize by currency
    const parseClosingBalancesByCurrency = (data) => {
        const closingBalancesByCurrency = {};

        Object.entries(data).forEach(([currency, balancesByMonth]) => {
            closingBalancesByCurrency[currency] = {}
            Object.entries(balancesByMonth).forEach(([date, balance]) => {
                closingBalancesByCurrency[currency][date.slice(0, 7)] = balance
            });
        });

        // myDebugPrint(`closingBalancesByCurrency: ${JSON.stringify(closingBalancesByCurrency)}`)

        return closingBalancesByCurrency;
    };

    const closingBalancesByCurrency = parseClosingBalancesByCurrency(closingBalances);

    // Get all months in the range
    const getMonthsInRange = (start, end) => {
        const months = [];
        let current = new Date(start);

        while (current <= end) {
            const month = `${current.getFullYear()}-${(current.getMonth() + 1).toString().padStart(2, '0')}`;
            months.push(month);
            current.setMonth(current.getMonth() + 1);
        }

        return months;
    };

    const getMonthsInText = (months) => {
        return months.map(month => formatMonth(month));
    };

    return (
        Object.keys(currencyData).map(currencyCode => {
            const { inflows, outflows, netChangeInCash, earliestDate, latestDate } = currencyData[currencyCode];
            const netBalance = closingBalancesByCurrency[currencyCode];
            const months = getMonthsInRange(earliestDate, latestDate);
            const monthsInText = getMonthsInText(months);

            // Prepare chart data
            const dataFlows = {
                labels: monthsInText,
                datasets: [
                    {
                        label: 'Поступления',
                        data: months.map(month => inflows[month] ?? 0),
                        backgroundColor: inflowsColor,
                    },
                    {
                        label: 'Платежи',
                        data: months.map(month => outflows[month] ?? 0),
                        backgroundColor: outflowsColor,
                    },
                ],
            };
            // Prepare chart data
            const dataNetCahnge = {
                labels: monthsInText,
                datasets: [
                    {
                        label: 'Сальдо потоков',
                        data: months.map(month => netChangeInCash[month] ?? 0),
                        backgroundColor: netflowsColor,
                    },
                ],
            };
            // Prepare chart data
            const dataClosingBalanceMonthly = {
                labels: monthsInText,
                datasets: [
                    {
                        label: 'Остатки на конец месяца',
                        data: months.map(month => netBalance[month] ?? 0),
                        backgroundColor: balancesColor,
                    },
                ],
            };


            const optionsFlows = {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom'
                    },
                    title: {
                        display: false,
                    },
                    datalabels: { display: false },
                },
                scales: {
                    x: {
                        beginAtZero: true,
                    },
                    y: {
                        beginAtZero: true,
                        ticks: {
                            callback: function (value) {
                                return shortenCashNumbers({ number: value });
                            }
                        }
                    }
                },
            };

            return (
                <CashCardTemplate
                    key={`overallByMonth_${currencyCode}`}
                    title="Совокупные движения по счетам"
                    subheader={`открытым в ${getCurrencyName(currencyCode)}`}
                    cardcontent={
                        <CardContent>
                            <Grid container spacing={2} direction={isSmallScreen ? 'column' : 'row'}>
                                <Grid item xs={12} md={4}>
                                    <Box sx={{ minHeight: '350px', width: '100%', }} >
                                        <Bar data={dataFlows} options={optionsFlows} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box sx={{ minHeight: '350px', width: '100%', }} >
                                        <Line data={dataNetCahnge} options={optionsFlows} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box sx={{ minHeight: '350px', width: '100%', }} >
                                        <Line data={dataClosingBalanceMonthly} options={optionsFlows} />
                                    </Box>
                                </Grid>
                            </Grid >
                        </CardContent>
                    }
                />
            );
        })
    );
};

export default OverallByMonths;
