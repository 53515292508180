import React from 'react';
import { ThemeProvider, IconButton, } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { HelpOutline } from '@mui/icons-material';

const IntroHelpButton = ({ onClick }) => {

    // const theme = createTheme({
    //     components: {
    //         MuiIconButton: {
    //             variants: [
    //                 {
    //                     props: { variant: 'slicerOn' },
    //                     style: {
    //                         backgroundColor: backgroundColorOn ?? '#d0e2cd',
    //                         border: borderColorOn ?? '#d0e2cd',
    //                         borderWidth: '2px',
    //                         borderStyle: 'solid',
    //                         marginLeft: '15px',
    //                     },
    //                 },
    //                 {
    //                     props: { variant: 'slicerOff' },
    //                     style: {
    //                         backgroundColor: backgroundColorOff ?? '#fff',
    //                         border: borderColorOff ?? '#d0e2cd',
    //                         borderWidth: '2px',
    //                         borderStyle: 'solid',
    //                         marginLeft: '15px',
    //                     },
    //                 },
    //             ],
    //             styleOverrides: {
    //                 root: {
    //                 },
    //             },
    //         },
    //     },
    // });
    return (
        // <ThemeProvider theme={theme}>

        <IconButton
            size='small'
            onClick={onClick}
        // variant={isOn ? 'slicerOn' : 'slicerOff'}
        >
            <HelpOutline />
        </IconButton>
        // </ThemeProvider>
    );
}

export default IntroHelpButton;